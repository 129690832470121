import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const CategoryLabel = ({ intl }) => (
      <FormattedMessage id="resourcesDetail_Category_Label"/>
 
 )
 
 export default injectIntl(CategoryLabel)

