import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import CardContainer from '../components/Containers/CardContainer/CardContainer'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/templates/blog.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavDropdown, Button, Image, Form, Input } from "react-bootstrap"
import config from '../utils/siteConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import PublishedDateLabel from '../components/Atoms/resourcesDetail/PublishedDateLabel/PublishedDateLabel'
import CategoryLabel from '../components/Atoms/resourcesDetail/CategoryLabel/CategoryLabel'
import AuthorLabel from '../components/Atoms/resourcesDetail/AuthorLabel/AuthorLabel'
import TagLabel from '../components/Atoms/resourcesDetail/TagLabel/TagLabel'
import SourceLinkLabel from '../components/Atoms/resourcesDetail/SourceLinkLabel/SourceLinkLabel'

const NewsTemplate = ({ data, pageContext }) => {
    debugger;
  
  const {
    publishDate,
    publisher,
    body,
    tags,
    categories,
    metaData,
    id,
    headline,
    headerImage,
    contentType,
    sourceLink,
    previewImage
    } = data.contentfulNews

  const resource = data.contentfulNews

  return (
    <Layout 
    IsHeaderImageDark={true}>

        <Container>
                <Row>
                    <div class="mt-5 text-left pl-3"><a  href={config.navigationURL_resourceCenter}> <FontAwesomeIcon icon={faArrowLeft} /> {' '} {contentType}</a></div>
                </Row>
                {/* ←  */}
                <Row>
                    <Col lg="8">
                        <h1>{headline.headline}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="8">
                      <div  class="pt-5">
                        <Image src={headerImage.file.url} fluid alt=""/>

                        <p class="pt-5 pb-3">
                        {documentToReactComponents(body.json)}
                        </p>

                      </div>

                    </Col>

                    <Col lg="4">
                        <p class="pb-3">
                            <h6><PublishedDateLabel /></h6>
                            <span> {publishDate}</span>
                        </p>
                        <p class="pb-3">
                            <h6><AuthorLabel /></h6>
                            <span><a>{publisher}</a></span>
                        </p>
                        {contentType.replace(/\s+/g, '-').toLowerCase() ==="news" &&
                          <p class="pb-3">
                            <h6><PublishedDateLabel /></h6>
                              <span><a href={sourceLink}>{sourceLink}</a></span>
                          </p>
                        }
                        <p class="pb-3">
                          <h6><CategoryLabel /></h6>
                            <span>
                            
                            {categories.map(category => (
                                <a class="btn btn-topics">{category.title}</a>
                                // {`/tag/${tag.slug}/`}
                            ))}
                            
                            </span>
                        </p>
                        <p class="pb-3">
                          <h6><TagLabel /></h6>
                            <span>
                            {tags.map(tag => (
                                <a class="btn btn-topics">{tag.title}</a>
                                // {`/tag/${tag.slug}/`}
                            ))}
                            </span>
                        </p>
                    </Col>
                </Row>
                <Row >
                    <div class="mt-5 text-left pl-3">
                        <p class="pb-5">   
                            <h6>TAGS</h6>
                            <span>
                                {tags.map(tag => (
                                    <a class="btn btn-topics">{tag.title}</a>
                                ))}
                            </span>
                        </p>


                    </div>
              
                </Row>
        </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!,$locale: String!) {
    contentfulNews(
      metaData: {
        slug: {eq: $slug}
        }, 
        node_locale: {eq: $locale}) {
      id
      contentType
      metaData {
        title
        slug
      }
      headerImage {
        file {
          url
          fileName
        }
      }
      publisher
      publishDate(formatString: "MMMM DD, YYYY")
      sourceLink
      categories {
        id
        slug
        title
      }
      tags {
        id
        slug
        title
      }
      headline {
        headline
      }
      body {
          body
          json
      }
      previewImage {
        file {
          url
          fileName
        }
      }
   
    }
  }
`

export default NewsTemplate
