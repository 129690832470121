import React from "react"
import CardContainerStyle from "./cardContainer.module.scss"
import "../../../styles/fonts.scss"
import "../../../styles/bootstrap.css"
import "../../../styles/components/buttons.css"
import "../../../styles/global.scss"
import "../../../styles/components/cards.css"

export default function CardContainer({ children }) {
  return (
    <section>
      <div class="bg-white">
        <div class="row">{children}</div>
      </div>
    </section>
  )
}

{
  /* <div className={CardContainerStyle.wrapper}>
        {children}
    </div> */
}
